<template>
    <div class="calendar-container" @click="closeDropdown">
        <div v-if="loading" class="loading-overlay">
            <div class="loading-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <!-- 로딩 중 표시 -->

        <div v-else class="calendar" @click.stop>
            <div class="calendar-header">
                <button @click="prevMonth">◀</button>
                <span>{{ year }}년 {{ month + 1 }}월</span>
                <button @click="nextMonth">▶</button>
            </div>
            <div class="calendar-weekdays">
                <div v-for="day in weekdays" :key="day" class="weekday">{{ day }}</div>
            </div>
            <div v-if="daysInMonth.length" class="calendar-days">
                <div
                    class="cal-day"
                    :class="{ today: day?.date && isToday(day.date) }"
                    v-for="day in daysInMonth"
                    :key="day?.date || day"
                    @click="day?.date && handleDayClick(day.date, $event)"
                >
                    <div
                        class="day"
                        :class="{
                            weekend: day?.date && isWeekend(day.date),
                            'empty-day': !day,
                        }"
                        @click.stop
                    >
                        {{ day?.day || '' }}
                    </div>
                    <img
                        v-if="day?.date && pastEvents?.[day.date]"
                        :src="imageUrls.STAMP"
                        class="has-data"
                        @click.stop
                    />
                    <img
                        v-if="day?.date && futureEvents?.[day.date] && isFutureDate(day.date)"
                        class="has-data"
                        @click.stop
                        :src="getFutureEventImage(day.date)"
                    />
                    <img
                        v-if="day?.date && commentEvents?.[day.date] && commentEvents[day.date].length > 0"
                        class="has-data"
                        @click.stop
                        :src="imageUrls.COMMENT"
                    />
                </div>
                <!-- 미래 예약 이벤트가 존재할 경우 -->
            </div>
        </div>
        <!-- 드롭다운 창 -->
        <div
            v-if="showDropdown"
            :style="{ top: dropdownPosition.top, left: dropdownPosition.left }"
            class="dropdown-content"
            @click.stop
        >
            <div class="dropdown-data">
                <div class="dropdown-header">
                    <h3>{{ selectedDate }}의 동화</h3>
                </div>

                <div v-if="pastEvents[selectedDate]" class="dropdown-list">
                    <div v-for="(event, index) in pastEvents[selectedDate]" :key="index" class="list-data">
                        <img :src="event.fairyTaleImage" class="dropdown-data-img" />
                        <p>{{ event.fairyTaleTitle }}</p>
                        <!-- 예약 여부 체크박스 (읽기 전용) -->
                        <input
                            type="checkbox"
                            class="reservation-checkbox"
                            :checked="isReserved(event.readsDay, event.fairyTaleTitle)"
                            disabled
                        />
                    </div>
                </div>

                <div v-else-if="isFutureDate(selectedDate) && futureEvents[selectedDate]" class="dropdown-list">
                    <div
                        v-for="(event, index) in Array.isArray(futureEvents[selectedDate])
                            ? futureEvents[selectedDate]
                            : [futureEvents[selectedDate]]"
                        :key="index"
                        class="list-data"
                    >
                        <img v-if="event.fairyTaleImage" :src="event.fairyTaleImage" class="dropdown-data-img" />
                        <p>{{ event.fairyTaleTitle }}</p>
                        <button @click="cancleReservation(event.reservationId)">x</button>
                    </div>

                    <button @click="makeReservation()" class="event-list-button">예약하기</button>
                </div>
                <div v-else-if="isFutureDate(selectedDate)" class="dropdown-list">
                    <button @click="makeReservation()" class="event-list-button">예약하기</button>
                </div>
                <div v-else class="dropdown-list">
                    <span>읽은동화가 없어요!( ᴗ_ᴗ̩̩ ) </span>
                </div>

                <!-- 댓글 출력 필드 -->
                <div
                    v-if="pastEvents[selectedDate] && pastEvents[selectedDate][0]?.dailyComment"
                    class="comment-display"
                >
                    <h4>엄마의 한말씀</h4>
                    <p class="comment-text">
                        {{ pastEvents[selectedDate][0].dailyComment }}
                        <span v-if="pastEvents[selectedDate][0].useComplimentBadge" class="compliment-badge"> 👍 </span>
                    </p>
                </div>

                <!-- 댓글만 존재할 경우 별도 commentEvents에서 데이터 출력 -->
                <div
                    v-else-if="commentEvents[selectedDate] && commentEvents[selectedDate][0]?.dailyComment"
                    class="comment-display"
                >
                    <h4>엄마의 한말씀</h4>
                    <p class="comment-text">
                        {{ commentEvents[selectedDate][0].dailyComment }}
                        <span v-if="commentEvents[selectedDate][0]?.useComplimentBadge" class="compliment-badge">
                            👍
                        </span>
                    </p>
                </div>

                <!-- 댓글 작성 필드 (오늘 이전 날짜이고 댓글이 없는 경우에만 표시) -->
                <div
                    class="comment-section"
                    v-else-if="
                        isPastDate(selectedDate) &&
                        !(pastEvents[selectedDate] && pastEvents[selectedDate][0]?.dailyComment) &&
                        !(commentEvents[selectedDate] && commentEvents[selectedDate][0]?.dailyComment)
                    "
                >
                    <h4>내아이 칭찬타임</h4>
                    <input
                        type="text"
                        v-model="dailyComment"
                        class="comment-input"
                        placeholder="오늘의 독서 활동에 대한 총평을 작성하세요..."
                    />
                    <div class="badge-setting">
                        <input type="checkbox" v-model="useComplimentBadge" /> 칭찬도장 추가
                    </div>
                    <button @click="submitDailyComment(selectedDate)" class="comment-button">댓글 작성</button>
                </div>
            </div>
            <button class="close-button" @click="closeDropdown">x</button>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import { useProfileStore } from '@/stores/profile';
import { storeToRefs } from 'pinia';
import { TALE_API_URL, IMAGE_SERVER_URL } from '@/constants/api';

export default {
    setup() {
        const profileStore = useProfileStore();
        const { selectedProfile } = storeToRefs(profileStore);
        const loading = ref(true);
        return { profileStore, selectedProfile, loading };
    },
    data() {
        return {
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            selectedDate: null,
            dayData: null,
            weekdays: ['일', '월', '화', '수', '목', '금', '토'],
            pastEvents: {}, // 과거 데이터 저장 객체 (날짜를 키로)
            futureEvents: {}, // 예약 데이터 저장 객체
            daysInMonth: [],
            showDropdown: false,
            dropdownPosition: { top: '0px', left: '0px' },
            dailyComment: '', // 댓글 입력 필드 상태
            commentData: {},
            commentEvents: {},
            useComplimentBadge: false, // 칭찬도장 여부
            imageUrls: {
                STAMP: `${IMAGE_SERVER_URL}/src/stamp.png`,
                COMMENT: `${IMAGE_SERVER_URL}/src/comment.png`,
                RESERVATION: `${IMAGE_SERVER_URL}/src/reservation.png`,
            },
        };
    },
    watch: {
        year() {
            this.updateDaysInMonth();
        },
        month() {
            this.updateDaysInMonth();
        },
        selectedProfile: {
            handler(newProfile) {
                if (newProfile && newProfile.id) {
                    this.updatePastEvents();
                    this.updateFutureEvents();
                }
            },
            immediate: true,
        },
    },
    async mounted() {
        this.updateDaysInMonth();
        if (this.selectedProfile && this.selectedProfile.id) {
            await this.fetchAllEvents();
            await this.updatePastEvents();
            await this.updateFutureEvents();
        } else {
            console.error('선택된 프로필이 없습니다. 데이터 요청이 중단됩니다.');
        }
        // this.loadComments(); // 댓글 데이터 로드
        document.addEventListener('keydown', this.handleKeydown);
        window.addEventListener('message', this.handleMessage, false);
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.handleKeydown);
        window.removeEventListener('message', this.handleMessage, false);
    },
    methods: {
        async fetchAllEvents() {
            try {
                await Promise.all([this.updatePastEvents(), this.updateFutureEvents()]);
                this.$nextTick(() => {
                    this.loading = false; // 모든 데이터가 로드된 후 로딩 종료
                });
            } catch (error) {
                console.error('이벤트 로드 중 오류 발생:', error);
            }
        },
        async updateDaysInMonth() {
            this.loading = true;

            const date = new Date(Date.UTC(this.year, this.month, 1));
            const firstDay = date.getUTCDay();
            const days = [];

            for (let i = 0; i < firstDay; i++) {
                days.push(null);
            }

            while (date.getUTCMonth() === this.month) {
                const formattedDate = `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(
                    2,
                    '0',
                )}-${String(date.getUTCDate()).padStart(2, '0')}`;
                days.push({ day: date.getUTCDate(), date: formattedDate });

                date.setUTCDate(date.getUTCDate() + 1);
            }

            this.daysInMonth = days;
            this.loading = false;
        },
        async updatePastEvents() {
            try {
                const historyResponse = await axios.get(
                    `${TALE_API_URL}/api/history/pastData/${this.profileStore.selectedProfile.id}`,
                );
                // 날짜를 키로 가지는 객체로 변환
                this.pastEvents = historyResponse.data.reduce((acc, event) => {
                    if (!acc[event.readsDay]) acc[event.readsDay] = [];
                    acc[event.readsDay].push(event);
                    return acc;
                }, {});
                const commentResponse = await axios.get(
                    `${TALE_API_URL}/api/comment/${this.profileStore.selectedProfile.id}`,
                );
                const commentData = commentResponse.data;

                // 댓글 데이터를 별도의 commentEvents 객체에 저장
                this.commentEvents = commentData.reduce((acc, comment) => {
                    // 이력 데이터가 없는 경우에만 commentEvents에 추가
                    if (!this.pastEvents[comment.readsDay]) {
                        acc[comment.readsDay] = [
                            {
                                dailyComment: comment.dailyComment,
                                useComplimentBadge: comment.useComplimentBadge,
                            },
                        ];
                    }
                    return acc;
                }, {});

                // 이력 데이터가 존재하는 경우에는 이력 데이터에 댓글 병합
                commentData.forEach((comment) => {
                    if (this.pastEvents[comment.readsDay]) {
                        this.pastEvents[comment.readsDay].forEach((event) => {
                            event.dailyComment = comment.dailyComment;
                            event.useComplimentBadge = comment.useComplimentBadge;
                        });
                    }
                });
            } catch (error) {
                console.error('과거 정보를 가져오는 중 오류 발생:', error);
            }
        },
        saveComment(event, date) {
            if (!event.comment || event.comment.trim() === '') return;
            // 새로운 댓글 추가
            console.log(date);
            event.comments.push(event.comment);
            event.comment = ''; // 입력 필드 초기화
        },
        async updateFutureEvents() {
            try {
                const response = await axios.get(
                    `${TALE_API_URL}/api/reservation/load/${this.profileStore.selectedProfile.id}`,
                );
                this.futureEvents = response.data.reduce((acc, event) => {
                    acc[event.reservationDate] = event;
                    return acc;
                }, {});
            } catch (error) {
                console.error('미래 예약 정보를 가져오는 중 오류 발생:', error);
            }
        },
        isWeekend(dateString) {
            const date = new Date(dateString);
            const dayOfWeek = date.getUTCDay();
            return dayOfWeek === 0 || dayOfWeek === 6;
        },
        isToday(dateString) {
            const today = new Date().toISOString().slice(0, 10);

            return today === dateString;
        },
        prevMonth() {
            if (this.month === 0) {
                this.month = 11;
                this.year -= 1;
            } else {
                this.month -= 1;
            }
            this.updateDaysInMonth();
        },
        nextMonth() {
            if (this.month === 11) {
                this.month = 0;
                this.year += 1;
            } else {
                this.month += 1;
            }
            this.updateDaysInMonth();
        },
        handleDayClick(date, event) {
            this.selectedDate = date;

            const rect = event.target.getBoundingClientRect();
            this.dropdownPosition = {
                top: `${rect.bottom + window.scrollY}px`,
                left: `${rect.left + window.scrollX}px`,
            };

            this.showDropdown = true;
        },
        isFutureDate(dateString) {
            const today = new Date();
            const selected = new Date(dateString);
            return selected > today;
        },
        isReserved(readsDay, title) {
            const events = this.futureEvents[readsDay];
            if (!events) {
                return false;
            } else {
                if (events.fairyTaleTitle === title) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        makeReservation() {
            const searchUrl = `/search?selectedDate=${this.selectedDate}`;
            window.open(searchUrl, 'searchWindow', 'width=1280,height=800');
            // 자식 창에서 postMessage로 전달받은 데이터를 처리할 리스너 추가
            window.addEventListener('message', this.handleMessage);
        },
        async cancleReservation(reservationId) {
            try {
                // 예약 취소 요청
                const response = await axios.delete(`${TALE_API_URL}/api/reservation/cancle/${reservationId}`);
                if (response.status == 200) {
                    alert(response.data);
                    await this.updateFutureEvents(); // 예약 데이터 최신화
                }
            } catch (error) {
                console.error('예약 취소 중 오류 발생:', error);
                alert(`예약 취소 중 오류가 발생했습니다: ${error.message}`);
            }
            this.$router.go(0);
        },
        closeDropdown() {
            this.showDropdown = false;
            this.selectedDate = null;
        },
        handleKeydown(event) {
            if (event.key === 'Escape' || event.key === 'Esc') {
                this.closeDropdown();
            }
        },
        async handleMessage(event) {
            if (event.origin !== window.location.origin) return; // 동일 출처 확인
            const { storyId, selectedDate } = event.data;
            if (storyId && selectedDate) {
                const reservationData = {
                    profileId: this.selectedProfile.id, // 현재 선택된 프로필의 ID
                    fairyTaleId: storyId, // 동화 제목
                    reservationDate: selectedDate,
                }; // 예약 날짜

                try {
                    await axios.post(`${TALE_API_URL}/api/reservation/add`, reservationData);
                    alert('동화 등록 완료했습니다.');
                    await this.updateFutureEvents(); // 예약 데이터 최신화
                    this.closeDropdown(); // 드롭다운 닫기
                } catch (error) {
                    console.error('예약 추가 중 오류 발생:', error);
                    alert(`예약 추가 중 오류가 발생했습니다: ${error.message}`);
                }
            }
        },
        isFutureDay(dateString) {
            const today = new Date();
            const selectedDate = new Date(dateString);

            // 오늘 날짜보다 이후인 경우에만 true 반환
            return selectedDate > today.setHours(0, 0, 0, 0);
        },

        async submitDailyComment(date) {
            try {
                const commentData = {
                    readsDay: date, // 날짜 데이터
                    dailyComment: this.dailyComment, // 댓글 내용
                    useComplimentBadge: this.useComplimentBadge, // 칭찬 도장 여부
                    profileId: this.profileStore.selectedProfile.id, // 프로필 ID
                };

                // 백엔드로 POST 요청 전송
                await axios.post(`${TALE_API_URL}/api/comment/saveComment`, commentData);

                await this.updatePastEvents();

                this.dailyComment = '';
                this.useComplimentBadge = false;
                this.closeDropdown();
            } catch (error) {
                console.error('댓글 저장 중 오류 발생:', error);
            }
        },

        // 오늘 이전 날짜인지 확인
        isPastDate(dateString) {
            const today = new Date().toISOString().slice(0, 10);
            return dateString < today;
        },
        getFutureEventImage(date) {
            const event = this.futureEvents[date];
            return event && event.imageUrl ? event.imageUrl : this.imageUrls.RESERVATION;
        },
    },
};
</script>
<style scoped>
.loading {
    text-align: center;
    font-size: 1.5rem;
    margin-top: 200px;
}
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loading-spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.calendar-container {
    margin-top: 20px;
    padding-top: 20px;
    width: 60%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.calendar-weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 90%;
    margin-bottom: 10px;
    font-weight: bold;
}

.weekday {
    text-align: center;
}

.calendar-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    width: 90%;
    height: 80%;
}

.cal-day {
    display: flex;
    cursor: pointer;
    border: 1px solid #f0c674;
    position: relative;
}

.day {
    cursor: pointer;
    font-size: 1.2rem;
    width: 60px;
    height: 60px;
}

.has-data {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
}

.weekend {
    color: #f44336;
}

.empty-day {
    background: none;
    pointer-events: none;
}

.dropdown-content {
    position: absolute;
    border: 1px solid #f0c674;
    background: #fff;
    display: flex;
    padding: 10px;
    border-radius: 10px;
    z-index: 1000;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease;
    width: 400px;
}
.dropdown-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    width: 100%;
}
.dropdown-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border: 1px solid #f0c674;
    border-radius: 10px;
    margin-bottom: 10px;
}
.dropdown-list p {
    margin: 0;
    font-size: medium;
    width: 70%;
    padding-left: 5px;
}
.dropdown-list span {
    padding: 10px;
}
.list-data {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 10px;
}
.dropdown-data-img {
    width: 50px;
    object-fit: cover;
    border-radius: 10px;
    margin-left: 5px;
}
.event-list-button {
    background-color: #4caf50;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    width: 50%;
    align-self: center;
    margin: 10px;
}
.profile-container {
    width: 100%;
    height: 200px;
    border: 1px solid #365563;
    margin-top: 20px;
    padding: 10px;
    text-align: center;
    font-size: 1.2rem;
}

button {
    background: none;
    border: 1px solid #2b2929;
    border-radius: 5px;
}

.close-button {
    font-size: 1rem;
    border-radius: 50%;
    border: 1px solid #7e7e7e;
    background: #7e7e7e;
    color: #fff;
    position: absolute;
    right: 10px;
    top: 10px;
}
li {
    list-style-type: none; /* 기본 점표시 제거 */
    padding-left: 0; /* 왼쪽 패딩 제거 */
    margin-left: 0; /* 왼쪽 마진 제거 */
}
ul {
    padding: 0;
}
.today {
    background-color: #f2e88d; /* 오늘 날짜의 배경색 노란색으로 설정 */
}

/* 스타일 정의 */
.comment-section {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #f0c674;
    border-radius: 10px;
}

.comment-input {
    width: 95%;
    height: 20px;
    margin: 10px;
}

.comment-button {
    background-color: #4caf50;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    width: 50%;
    align-self: center;
    margin-bottom: 20px;
}

.comment-display {
    width: 100%;
    border: 1px solid #f0c674;
    border-radius: 10px;
}

.comment-text {
    padding: 10px;
}

.compliment-badge {
    padding: 2px 6px;
    border-radius: 3px;
    margin-left: 5px;
}

.badge-setting {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 10px;
}

.badge-setting input {
    margin-right: 5px;
}

.dropdown-content h4 {
    margin: 0;
    padding: 10px;
}
.dropdown-content p {
    margin: 0;
    padding: 10px;
}
</style>
